.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: no-wrap;
  gap: 2rem;
  padding-top: 2rem;
  width: 100%;
}
.container_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: no-wrap;
  gap: 0.25rem;
  border: 1px solid grey;
  padding: 0.5rem;
  width: 100%;
}
.container_search input {
  border: none;
  outline: none;
  width: 100%;
}
.container_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.container_list_item img {
  height: 200px;
  /* width: 125px; */
}


form {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
}
input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
}


.results-wrap {
  width: 70%;
  min-height: 11rem;
  max-height: auto;
  margin-top: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.list-item {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-right: 1rem;
}

.list-item a {
  color: grey;
  text-decoration: none;
}

.list-item a:hover {
  color: tomato;
  transition: 200ms ease-in;
}